import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from '../vendor/gsap/ScrollSmoother';
import smoov from '../util/smooth-scroll';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

smoov();

const textFades = gsap.utils.toArray('.js-text-fade');
const textFades2 = gsap.utils.toArray('.js-text-fade-2');

gsap.utils.toArray('.js-text-fade').forEach((item) => {
    const animation = gsap.timeline({ paused: true });

    animation.to(item, {
        duration: 2,
        ease: 'sine.out',
        '--mask': 'linear-gradient(-45deg, transparent -50%, black 0%)',
    });

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: item,
            start: 'top bottom',
            end: 'bottom top',
            onEnter: () => {
                if (!$(item).hasClass('activated')) {
                    animation.play([0]);
                    $(item).addClass('activated');
                }
            },
            onEnterBack: () => {
                if (!$(item).hasClass('activated')) {
                    animation.play([0]);
                    $(item).addClass('activated');
                }
            },
        },
    });

    return tl;
});

textFades2.forEach((item) => {
    const animation = gsap.timeline({ paused: true });

    animation.to(item, {
        duration: 2,
        ease: 'sine.out',
        '--mask': 'linear-gradient(0deg, transparent -50%, black 0%)',
    });

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: item,
            scrub: true,
            start: 'top bottom',
            end: 'bottom top',
            onEnter: () => {
                if (!$(item).hasClass('animated')) {
                    animation.play([0]);
                    $(item).addClass('animated');
                }
            },
        },
    });

    return tl;
});
