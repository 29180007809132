import { gsap, ScrollTrigger } from 'gsap/all';
import { ScrollSmoother } from '../vendor/gsap/ScrollSmoother';
import smoov from '../util/smooth-scroll';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

smoov();

const bgPhoto = $('.bg-photo-callout .background-photo');

if ($(bgPhoto).length > 0) {
    // BG callout picture
    gsap.set(bgPhoto, { scale: 1.2 });

    const bgPhotoTl = gsap.timeline({
        paused: true,

        scrollTrigger: {
            trigger: bgPhoto,
            start: 'top bottom',
            end: 'bottom 50%',
            scrub: true,
        },
    });

    bgPhotoTl.to(bgPhoto, {
        scale: 1,
    });
}
