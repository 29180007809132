import itsMobile from './is-mobile';

function leaveState() {
    $('.cursor-text').removeClass('cursor-text--anim');
    $('.cursor').removeClass('background-circle--scale');
    $('.cursor-text').find('span').html('');
    $('body').removeClass('hide-cursor');
}

function enterState() {
    $('.cursor-text').addClass('cursor-text--anim');
    $('.cursor').addClass('background-circle--scale');
    $('body').addClass('hide-cursor');
}

if (!itsMobile()) {
    // Careers flyout carousel slider
    $('.container.main-slider').on('mouseenter', (e) => {
        $('body').addClass('hide-cursor');
        $('.cursor-text').addClass('cursor-text--anim');
        $('.cursor').addClass('background-circle--scale');
        $('.cursor-text').find('span').html('drag');
    });

    $('.container.main-slider').on('mousedrag', (e) => {
        enterState();
        $('.cursor-text').find('span').html('drag');
    });

    $('.container.main-slider').on('mouseleave', () => {
        leaveState();
    });

    $('.preview-video-container').on('mouseenter', () => {
        enterState();
        $('.cursor-text').find('span').html('watch video');
    });

    $('.preview-video-container').on('mouseleave', () => {
        leaveState();
    });

    // Movement
    $(document).on('mousemove', (e) => {
        $('.cursor').removeAttr('inert');
        $('.cursor').css({

            transform: `translate3d(${e.clientX}px, ${e.clientY}px, 0px)`,
        });
    });
}
