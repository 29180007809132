import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import itsMobile from './is-mobile';
import { ScrollSmoother } from '../vendor/gsap/ScrollSmoother';
import smoov from './smooth-scroll';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

smoov();

if (!itsMobile() && !$('body').hasClass('contact-page')) {
    const footerLogo = $('.footer-logo-wrapper');
    const tl = gsap.timeline({ paused: true });

    gsap.set(footerLogo, {
        x: 250,
        y: 200,
        rotationZ: -20,
    });

    tl.fromTo(footerLogo, {
        x: 250, y: 200,
    }, {
        duration: 1.4, x: 0, y: -20, rotationZ: 0, ease: 'power.2',
    });

    ScrollTrigger.create({
        trigger: '.main-footer',
        start: 'top bottom',
        end: 'bottom',
        scrub: false,

        onEnter: () => {
            if (!$('.footer-logo-wrapper').hasClass('activated')) {
                tl.play();
                $('.footer-logo-wrapper').addClass('activated');
            }
        },

    });
}
