import { gsap, ScrollTrigger } from 'gsap/all';

const mm = gsap.matchMedia();
let paddingBonus = '';
let currentHeight = '';

function paddingDecide() {
    // When these media queries match, the associated function will run
    mm.add('(min-width: 992px)', () => {
        paddingBonus = 50;
    });

    mm.add('(max-width: 992px)', () => {
        paddingBonus = 25;

        return () => {
            // custom cleanup code here (runs when it STOPS matching)
            paddingBonus = 50;
        };
    });
}

function activateTray(thisBtn) {
    const btn = thisBtn;

    if (!$(thisBtn).hasClass('active')) {
        const workType = $(btn).attr('data-work-type');
        const currentType = $(`.cat-pill-drawer.type-${workType}`);

        $('.js-filter-tray-btn').removeClass('active');
        $('.cat-pill-drawer').removeClass('active');

        $(thisBtn).addClass('active');
        $(currentType).addClass('active');

        paddingDecide();
        currentHeight = `${$(currentType).height() + paddingBonus}px`;

        gsap.to($('.cat-pill-container-grid'), { duration: 0.2, height: currentHeight });
    } else {
        $(thisBtn).removeClass('active');
        $('.cat-pill-drawer').removeClass('active');
        gsap.to('.cat-pill-container-grid', { duration: 0.5, height: '0', ease: 'ease-in' });
    }
}

if ($('.filter-tray-container').length > 0) {
    $('.js-filter-tray-btn').on('click', (e) => {
        activateTray(e.currentTarget);
    });
}
