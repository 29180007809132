import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import { gsap, ScrollTrigger } from 'gsap/all';
import { SplitText } from '../vendor/gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

defineElement(lottie.loadAnimation);
const lordIcons = document.querySelectorAll('lord-icon');

if ($('.two-core-teams-panel .icon-wrapper').length > 0) {
    [].forEach.call(lordIcons, (el) => {
        el.addEventListener('ready', () => {
            const lordIconElement = el;
            lordIconElement.player.speed = 0.5;
        });
    });
}

const twoTeamsPanel = $('.two-core-teams-panel');
function activateTwoTeamsBg() {
    twoTeamsPanel.addClass('activated');
}

function removeTwoTeamsBg() {
    twoTeamsPanel.removeClass('activated');
}

if ($('.two-core-teams-panel').length > 0) {
    ScrollTrigger.create({
        trigger: '.two-core-teams-panel',
        start: 'top 75%',
        end: 'bottom',
        onEnter: () => {
            activateTwoTeamsBg();
        },
        onEnterBack: () => {
            activateTwoTeamsBg();
        },
        onLeave: () => {
            removeTwoTeamsBg();
        },
        onLeaveBack: () => {
            removeTwoTeamsBg();
        },
    });

    const teamCards = $('.two-core-teams-panel .team-card .copy-wrap');
    const cardRow = $('.two-core-teams-panel .content-row');

    const mm = gsap.matchMedia();

    mm.add('(min-width: 768px)', () => {
        const opposedLettersTl = gsap.timeline({ paused: true });

        opposedLettersTl.to(teamCards, {
            duration: 1.2,
            x: 0,
            opacity: 1,
            ease: 'ease-in',
        });

        ScrollTrigger.create({
            trigger: cardRow,
            start: 'top 65%',
            end: 'bottom top',
            onEnter() {
                if (!$(cardRow).hasClass('activated')) {
                    opposedLettersTl.play();

                    $(cardRow).addClass('activated');
                }
            },

        });
    });

    // Remove activated class on resize so it can animate out again if needed.
    $(window).on('resize', () => {
        $(cardRow).removeClass('activated');
    });
}
