// Import Swiper and modules
import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';

function standardSwiper() {
    const swiper = new Swiper('.standard-slider', {
        modules: [Navigation, Pagination, Scrollbar],
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
        },
    });

    return swiper;
}

standardSwiper();
