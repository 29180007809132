import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from '../vendor/gsap/SplitText';
import { ScrollSmoother } from '../vendor/gsap/ScrollSmoother';
import smoov from '../util/smooth-scroll';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);
smoov();

let headlineWords;
let headlineLines;
function headlineAnimate(element) {
    if ($(element).hasClass('activated')) {
        return false;
    }
    const headline = element;
    const headlineSplit = new SplitText(headline, { type: 'words, lines', wordsClass: 'words', linesClass: 'lines-bruh' });
    headlineLines = headlineSplit.lines;
    headlineWords = headlineSplit.words;

    gsap.set(headlineLines, {
        // overflow: 'hidden',
        rotate: 15,
        transformOrigin: 'left top',
    });

    gsap.set(headlineWords, {
        display: 'inline',
        opacity: 0,
        top: 45,
        overflow: 'hidden',
    });

    $(element).addClass('activated');

    const tl = gsap.timeline()
        .to(headlineWords, {
            duration: 0.75, top: 0, opacity: 1, stagger: 0.05, ease: 'ease-in',
        })
        .to(headlineLines, {
            duration: 1.25, rotate: 0, ease: 'ease-in', stagger: 0.05,
        }, 0);

    return tl;
}

gsap.utils.toArray('.js-split-headline').forEach((item) => {
    ScrollTrigger.create({
        trigger: item,
        start: 'top bottom',
        end: 'bottom 95%',
        toggleActions: 'play none none none',
        onEnter: () => {
            headlineAnimate(item);
        },
    });
});
