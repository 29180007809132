import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import itsMobile from '../util/is-mobile';

gsap.registerPlugin(ScrollTrigger);

// Check if .work-scroller-wrapper exists first
if ($('.work-scroller-wrapper').length > 0) {
    const animation = gsap.timeline({ paused: true });

    if (!itsMobile()) {
        animation.to('.work-scroller-wrapper', {
            duration: 1.6,
            ease: 'ease-in',
            width: '120vw',
        }, '0');
    }

    ScrollTrigger.create({
        trigger: '.work-scroller-container',
        start: 'top bottom',
        end: 'bottom',
        scrub: false,

        onEnter: () => {
            animation.play();
        },
    });

    gsap.utils.toArray('.scroller').forEach((item) => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.work-scroller-container',
                scrub: true,
                start: 'top bottom',
                end: 'bottom top',
            },
        });

        // Medium screens
        if (window.innerWidth > 600 && window.innerWidth < 1200) {
            if ($(item).parent('.scroll-column').hasClass('vertical-scroll-south')) {
                gsap.set('.col-1 .scroller', {
                    transformOrigin: 'right top',
                });

                gsap.set(item, {
                    y: -700,
                });

                tl.fromTo(item, {
                    y: -700,

                }, {
                    ease: 'linear',
                    y: 270,
                });
            } else {
                gsap.set(item, {
                    y: -20,
                });

                tl.fromTo(item, {
                    y: -20,

                }, {
                    y: -510,
                    ease: 'linear',
                });
            }
        }

        // Desktop screens
        if (window.innerWidth >= 1200) {
            if ($(item).parent('.scroll-column').hasClass('vertical-scroll-south')) {
                gsap.set('.col-1 .scroller', {
                    transformOrigin: 'right top',
                });

                gsap.set(item, {
                    y: -1600,
                });

                tl.fromTo(item, {
                    y: -1600,

                }, {
                    ease: 'linear',
                    y: 470,
                });
            } else {
                gsap.set(item, {
                    y: -20,
                });

                tl.fromTo(item, {
                    y: -20,

                }, {
                    y: -810,
                    ease: 'linear',
                });
            }
        }

        // Mobile
        if (window.innerWidth < 600) {
            if ($(item).parent('.scroll-column').hasClass('vertical-scroll-south')) {
                gsap.set('.col-1 .scroller', {
                    transformOrigin: 'right top',
                });

                gsap.set(item, {
                    y: -510,
                });

                tl.fromTo(item, {
                    y: -510,

                }, {
                    ease: 'linear',
                    y: 180,
                });
            } else {
                gsap.set(item, {
                    y: -20,
                });

                tl.fromTo(item, {
                    y: -20,

                }, {
                    y: -310,
                    ease: 'linear',
                });
            }
        } // mobile
    });
}
