import { gsap, ScrollTrigger } from 'gsap/all';
import { SplitText } from '../vendor/gsap/SplitText';
import { ScrollSmoother } from '../vendor/gsap/ScrollSmoother';
import smoov from '../util/smooth-scroll';
import itsMobile from '../util/is-mobile';

gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother);
smoov();

function turboPeriods() {
    // Color .js-turbo-period classed elements' final period
    function turboPeriod(parent) {
        const currentHTML = $(parent).text();

        if (currentHTML.endsWith('.')) {
            const newHTML = `${currentHTML.substring(0, currentHTML.length - 1)}<span class='js-turbo-char text-turbo'>${currentHTML.slice(-1)}</span>`;
            $(parent).html(newHTML);
        }
    }
    if ($('.js-turbo-period').length > 0) {
        $('.js-turbo-period').each((e, index) => {
            turboPeriod(index);
        });
    }
}

function splits() {
    if ($('.js-split-lines-text').length > 0) {
        gsap.utils.toArray('.js-split-lines-text').forEach((item) => {
            const myText = $(item);
            let mySplitText;
            function createSplits() {
                mySplitText = new SplitText(myText, {
                    type: 'lines',
                    linesClass: 'js-split-lines',
                });
            }
            createSplits();
            $(window).on('resize', () => {
                mySplitText.revert();
            });
        });
    }
    if ($('.js-split-words-text').length > 0) {
        gsap.utils.toArray('.js-split-words-text').forEach((item) => {
            const myText = $(item);
            let mySplitText;
            function createSplits() {
                $(myText).addClass('activated');
                mySplitText = new SplitText(myText, {
                    type: 'words',
                    wordsClass: 'js-split-words',
                });
            }
            createSplits();
            $(window).on('resize', () => {
                mySplitText.revert();
            });
        });
    }

    if ($('.js-split-chars-text').length > 0) {
        gsap.utils.toArray('.js-split-chars-text').forEach((item) => {
            const myText = $(item);
            let mySplitText;
            function createSplits() {
                mySplitText = new SplitText(myText, {
                    type: 'chars,words,lines',
                    charsClass: 'js-split-chars',
                    reduceWhiteSpace: false,
                });
            }
            createSplits();
            $(window).on('resize', () => {
                mySplitText.revert();
            });
        });
    }
}

// From webflow: https://scroll-text-animations.webflow.io/
function createTextAnimations() {
    // Line Animation
    if ($('.js-line-animation').length > 0) {
        gsap.utils.toArray('.js-line-animation').forEach((item) => {
            const triggerElement = $(item);
            const targetElement = $(item).find('.js-split-lines');

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: triggerElement,
                    toggleActions: 'play play play play',
                    onEnter: () => {
                        $(triggerElement).addClass('activated');
                    },
                    onEnterBack: () => {
                        $(triggerElement).addClass('activated');
                    },
                    onLeaveBack: () => {
                        $(triggerElement).addClass('activated');
                    },
                },
            });
            tl.from(targetElement, {
                duration: 0.75,
                y: '150%',
                rotationX: -90,
                autoAlpha: 0,
                ease: 'power1.inOut',
                stagger: 0.05,
            }, 0);
        });
    }
    // Word Animation
    if ($('.js-word-animation').length > 0) {
        gsap.utils.toArray('.js-word-animation').forEach((item) => {
            const triggerElement = $(item);
            const targetElement = $(item).find('.js-split-words');
            const myText = $(item);

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: triggerElement,
                    toggleActions: 'play play play play',

                    onEnter: () => {
                        $(myText).addClass('activated');
                    },
                    onEnterBack: () => {
                        $(myText).addClass('activated');
                    },
                    onLeaveBack: () => {
                        $(myText).addClass('activated');
                    },
                },
            });

            tl.from(targetElement, {
                duration: 0.3,
                y: '80%',
                rotationX: -90,
                autoAlpha: 0,
                ease: 'power1.inOut',
                stagger: 0.03,
            });
        });
    }

    // Letter Animation
    if ($('.js-letter-animation').length > 0) {
        gsap.utils.toArray('.js-letter-animation').forEach((item) => {
            const triggerElement = $(item);
            const targetElement = $(item).find('.js-split-chars');
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: triggerElement,
                    toggleActions: 'play play play play',
                    onEnter: () => {
                        $(item).addClass('activated');
                    },
                    onEnterBack: () => {
                        $(item).addClass('activated');
                    },
                    onLeaveBack: () => {
                        $(item).addClass('activated');
                    },
                },
            });

            tl.from(targetElement, {
                duration: 0.3,
                y: '60%',
                autoAlpha: 0,
                rotationX: -90,
                ease: 'power1.inOut',
                stagger: 0.035,
            });
        });
    }
}

$(() => {
    if (itsMobile()) {
        turboPeriods();
    } else {
        turboPeriods();
        splits();
        createTextAnimations();
    }
});
