import Plyr from 'plyr/dist/plyr.min.js';
import { gsap, ScrollTrigger } from 'gsap/all';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SplitText } from '../vendor/gsap/SplitText';
import itsMobile from '../util/is-mobile';
import { ScrollSmoother } from '../vendor/gsap/ScrollSmoother';
import smoov from '../util/smooth-scroll';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText, ScrollToPlugin);
smoov();

function homeInit() {
    const introPanel = $('.home-page .masthead.intro-panel');
    const contentPanel = $('.home-page .intro-panel .content-panel');
    const playerFull = new Plyr('#hdr-player-full', { autoplay: true });
    const previewPanel = $('.video-preview-panel');
    const previewWrapper = $('.video-wrapper');
    const videoContainer = $('.video-container');
    const videoContent = $('.video-content');
    const previewVideo = $('.preview-video-container');
    const gradientCurtain = $('.dynamic-gradient-curtain');
    const video = $('#hdr-player-full');
    const mobileMediaQuery = window.matchMedia('(max-width: 1024px)');
    gsap.set(previewWrapper, {
        top: '50%',
    });

    gsap.set(introPanel, {
        width: '100%',
    });

    let circleWidth = '';
    let circleHeight = '';

    function circleSize() {
        if (mobileMediaQuery.matches) {
            circleWidth = '70vw';
            circleHeight = '70vw';
        } else {
            circleWidth = '45vw';
            circleHeight = '45vw';
        }

        return { circleWidth, circleHeight };
    }

    circleSize();

    // Expand timelines
    const expandTl = gsap.timeline({
        paused: true,
    });

    const mobileExpandTl = gsap.timeline({
        paused: true,
    });
    gsap.set(previewWrapper, { minHeight: circleHeight - 2 });

    if (mobileMediaQuery.matches) {
        mobileExpandTl
            .to(previewVideo, {
                duration: 0.9, ease: 'linear', width: '100%',
            })
            .to(previewPanel, {
                duration: 0.9,
                width: '100%',
                ease: 'linear',
            }, '0')
            .fromTo(previewWrapper, {
                width: circleWidth,
                height: circleHeight,
                borderRadius: '50%',
                padding: '0px',
                duration: 0.8,
                right: '0px',
                top: '0',
                left: 'auto',
            }, {
                duration: 0.8,
                width: '100%',
                height: 'auto',
                maxWidth: 'none',
                maxHeight: 'none',
                borderRadius: '50%',
                right: '0px',
                ease: 'power2.out',
                top: '0',
            }, '0')
            .to(previewWrapper, {
                borderRadius: '0px',
            })
            .to(videoContainer, { duration: 0.2, borderRadius: '0px', ease: 'linear' }, '0');
    } else {
        expandTl
            .to(previewVideo, {
                duration: 1,
                ease: 'linear',
                width: '100%',
            }, '0')
            .to('#main-logo', { opacity: 0 }, '0')
            .to(previewPanel, {
                duration: 0.9,
                width: '100%',
                ease: 'linear',
                onStart: () => {
                    $('.video-preview-panel').attr('data-expanded', 'expanded');
                    $(previewPanel).addClass('expand');
                },
            }, '0')
            .fromTo(previewWrapper, {
                width: circleWidth,
                height: circleHeight,
                minHeight: 'clamp(350px, 45vw, 650px)',
                minWidth: 'clamp(350px, 45vw, 650px)',
                maxWidth: '670px',
                maxHeight: '670px',
                borderRadius: '50%',
                marginTop: '0px',
                x: '0',
            }, {
                duration: 1,
                right: 0,
                width: '100%',
                height: '59.25vw',
                maxWidth: '1400px',
                maxHeight: 'clamp(750px, 100vh, 822px)',
                minHeight: 'clamp(350px, 45vw, 650px)',
                borderRadius: '4%',
                bottom: '50px',
                marginTop: '40px',
                x: '-2rem',
            }, '0')
            .to(gradientCurtain, {
                duration: 1,
                x: '-100vw',
                width: '200vw',
                ease: 'linear',
            }, '0')
            .to(contentPanel, {
                duration: 0.9,
                zIndex: 0,
                opacity: 0,
                x: -250,
                ease: 'linear',
                clearProps: 'x,opacity,zIndex',
            }, '0')
            .to(videoContainer, { duration: 1, borderRadius: '0px', ease: 'linear' }, '0');
    }

    // Starts video over
    function videoPop() {
        if (!$(video).hasClass('popped')) {
            $(video).attr('src');
            const src = $(video).attr('data-video-path');
            $(video).attr('src', src);
            $(video).addClass('popped');
        }
    }

    function videoStop() {
        $(video).removeClass('popped');
        $(video).attr('src', '');
    }

    // Open the video circle into a rectangle to view full width video
    function expand() {
        $('.video-preview-panel').attr('data-expanded', 'expanded');
        $(previewPanel).addClass('expand');
        $(videoContent).addClass('activated');
        $('body').addClass('video-open');
        $(previewPanel).addClass('video-loaded');

        if (mobileMediaQuery.matches) {
            mobileExpandTl.play(0);
        } else {
            expandTl.play(0);
        }

        videoPop();
    }

    function retractWork() {
        gsap.to('#main-logo', { duration: 0.5, opacity: 1 });
        $(previewPanel).removeClass('expand').removeClass('video-loaded');
        $(videoContent).removeClass('activated');
        $('.video-preview-panel').attr('data-expanded', 'collapsed');
        $('body').removeClass('video-open');
        videoStop();
        expandTl.reverse();
    }

    function retract() {
        retractWork();
    }

    function mobileInit() {
        gsap.set(contentPanel, {
            zIndex: 1,
            opacity: 1,
            x: 0,
        });
        $(previewPanel).addClass('loaded');
    }

    function scrollRevealMovie() {
        $(previewPanel).addClass('loaded');

        // Using two scrolltriggers, one to activate the expandTL and one to pin it
        ScrollTrigger.create({
            trigger: introPanel,
            start: 'top top',
            end: 'top+=20px',
            onLeave: () => { expandTl.play(); },
            onEnterBack: () => {
                if (!$(video).hasClass('popped')) {
                    retract();
                    $(previewVideo).trigger('play');
                }
            },
        });

        ScrollTrigger.create({
            trigger: introPanel,
            start: 'top+=80px',
            end: '+=300px',
            pin: true,
        });
    }

    $('#close_btn').on('click', (e) => {
        e.preventDefault();
        retract();
    });

    $('.js-trigger-full-video').on('click', (e) => {
        e.preventDefault();
        if ($(previewPanel).hasClass('video-loaded')) {
            // already playing, return
            return;
        } if (itsMobile()) {
            $(videoContent).addClass('activated');
            $(previewPanel).addClass('video-loaded');
            $(videoContainer).css('overflow', 'visible');
            expand();
            videoPop();
            playerFull.play();
        } else if ($(previewPanel).hasClass('expand')) {
            // running only if it has expanded
            gsap.to(window, {
                duration: 1,
                scrollTo: { y: '.video-wrapper', offsetY: 50 },
            });

            $(videoContent).addClass('activated');
            $('body').addClass('video-open');
            $(previewPanel).addClass('video-loaded');

            videoPop();

            return;
        }

        // standard expand
        $(videoContent).addClass('activated');
        gsap.to(window, {
            duration: 1,
            scrollTo: { y: '.video-wrapper', offsetY: 50 },
        });

        expand();
    });

    if (mobileMediaQuery.matches) {
        mobileInit();
        $('body').addClass('mobile-intro');
        gsap.set(previewWrapper, {
            transform: 'translate(0,0)',
        });
    } else {
        if (document.querySelector('.video-preview-panel')) {
            scrollRevealMovie();
            gsap.set(previewWrapper, {
                top: '50%',
            });
        }
    }
} // End home init

// Preloader stuff

let preVideo = document.getElementById('preview_video');
const svgPreloaderElement = document.getElementById('erSHO1A2vao1');
const body = document.querySelector('.home-page');
const svgPlayer = svgPreloaderElement ? svgPreloaderElement.svgatorPlayer : {};

function animTextTitle() {
    const item = $('.js-home-letter-animation');
    const triggerElement = $('.js-home-letter-animation');
    const targetElement = $('.js-home-letter-animation').find('.js-split-chars');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: triggerElement,
            onEnter: () => {
                $(item).addClass('activated');
            },
            onEnterBack: () => {
                $(item).addClass('activated');
            },
            onLeaveBack: () => {
                $(item).addClass('activated');
            },
        },
    });

    tl.from(targetElement, {
        duration: 0.5,
        y: '60%',
        autoAlpha: 0,
        rotationX: -90,
        ease: 'power1.inOut',
        stagger: 0.035,
    }, 0);

    const item2 = $('.js-home-text-fade');
    const animation = gsap.timeline({ paused: true });

    animation.to(item2, {
        duration: 2,
        ease: 'sine.out',
        '--mask': 'linear-gradient(-45deg, transparent -50%, black 0%)',
    }, 0);

    const tl2 = gsap.timeline({
        scrollTrigger: {
            trigger: item2,
            start: 'top bottom',
            end: 'bottom top',
            onEnter: () => {
                if (!$(item2).hasClass('activated')) {
                    animation.play([0]);
                    $(item2).addClass('activated');
                }
            },
            onEnterBack: () => {
                if (!$(item2).hasClass('activated')) {
                    animation.play([0]);
                    $(item2).addClass('activated');
                }
            },
        },
    });

    return tl2;
}

const shrinkPreloaderTl = gsap.timeline();

function segueIntro() {
    if (!$('.preloader-section').hasClass('complete')) {
        shrinkPreloaderTl.to(svgPreloaderElement, {
            duration: 0.5,
            scale: 0.5,
            ease: 'power4.out',
            transformOrigin: 'center',
            opacity: 0,

        }, '0').to('.preloader-section', {
            duration: 0.25,
            opacity: 0,
            ease: 'linear',
            onStart: () => {
                body.classList.remove('preloading');
                preVideo = document.getElementById('preview_video');
                preVideo.play();
            },
        }, '0').to('.preloader-section', {
            zIndex: -1,
            onComplete: () => {
                $('.preloader-section').addClass('complete');
            },
        });
    }
}

if ($('.home-page .masthead').length > 0) {
    const plyrElement = document.getElementById('hdr-player-full');

    if (typeof (plyrElement) !== 'undefined' && plyrElement != null) {
        homeInit();
    }

    preVideo.addEventListener('play', () => {
        animTextTitle();
        $('.video-wrapper').css('opacity', 1);
    });

    document.addEventListener('readystatechange', () => {
        const thisState = document.readyState;

        if (thisState === 'complete') {
            // loaded, speed up to the end
            svgPlayer.set('speed', 13);

            svgPlayer.on('end', () => {
                svgPlayer.pause();
                segueIntro();
            });
        }
    });

    // If this takes a long time just show the page
    setTimeout(() => {
        segueIntro();
    }, 28000);
}
