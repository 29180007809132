import isMobile from 'is-mobile';

const mobileOpts = {
    tablet: true,
    featureDetect: true,
};

export default function itsMobile() {
    return isMobile(mobileOpts);
}
