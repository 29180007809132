import { gsap, ScrollTrigger } from 'gsap/all';
import { ScrollSmoother } from '../vendor/gsap/ScrollSmoother';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function smoovCallback() {
    ScrollTrigger.removeEventListener('refresh', smoovCallback);

    $('body').addClass('smoov');
}

function smoothScrollTo() {
    const smoother = ScrollSmoother.create({
        wrapper: '#smooth-scroll-wrapper',
        content: '#smooth-scroll-content',
        smooth: 1.3,
        normalizeScroll: false,
        effects: true,
        ignoreMobileResize: false,
    });

    ScrollTrigger.addEventListener('refresh', smoovCallback);

    // Detect if a link's href goes to the current page
    function getSamePageAnchor(link) {
        if (
            link.protocol !== window.location.protocol
      || link.host !== window.location.host
      || link.pathname !== window.location.pathname
      || link.search !== window.location.search
        ) {
            return false;
        }

        return link.hash;
    }

    // Scroll to a given hash, preventing the event given if there is one
    function scrollToHash(hash, e) {
        const elem = hash ? document.querySelector(hash) : false;
        if (elem) {
            if (e) e.preventDefault();
            // gsap.to( window, { scrollTo: elem } );
            smoother.scrollTo(elem, true);
        }
    }

    // If a link's href is within the current page, scroll to it instead
    document.querySelectorAll('a[href]').forEach((a) => {
        a.addEventListener('click', (e) => {
            scrollToHash(getSamePageAnchor(a), e);
        });
    });

    // Scroll to the element in the URL's hash on load
    scrollToHash(window.location.hash);
}

export default function smoov() {
    if (ScrollTrigger.isTouch !== 1) {
        // only create if it's not a touch-only device...

        smoothScrollTo();
    }
    // If touch add auto
    $('html').addClass('scroll-behavior-auto');
    return false;
}
