import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
let tween = '';

if ($('.crawl-wrapper').length > 0) {
    tween = gsap.to('.crawl-wrapper', {
        x: '-200vw', repeat: -1, duration: 60, ease: 'linear',
    }).totalProgress(0.75).pause();

    gsap.set('.crawl-wrapper', { x: '500vw' });
}

function marqueeScroll() {
    const crawlTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.latest-news-panel',
            scrub: true,
            start: 'top 90%',
            end: 'bottom top',
            onUpdate: (self) => {
                if (self.direction === 1) {
                    gsap.to(tween, {
                        timeScale: 1,
                    });
                } else {
                    gsap.to(tween, {
                        timeScale: -1,
                    });
                }
            },
            onEnter: () => {
                tween.play();
            },
            onEnterBack: () => {
                tween.play();
            },
            onLeave: () => {
                tween.pause();
            },
            onLeaveBack: () => {
                tween.pause();
            },
        },
    });

    return crawlTl;
}

if ($('.crawl-wrapper').length > 0) {
    marqueeScroll();
}
