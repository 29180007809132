import { gsap, ScrollTrigger } from 'gsap/all';
import { SplitText } from '../vendor/gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const content = $('.our-why-panel .headline');
const headline = $('.our-why-panel .content .js-split-lines');
const headSplit = new SplitText(headline, { type: 'lines', linesClass: 'lines-bruh' });
const headLines = headSplit.words;

function runHeadlines() {
    return headLines;
}

if ($('.our-why-panel').length > 0) {
    runHeadlines();

    const linez = $(headline).find('.lines-bruh');
    gsap.set(linez, { opacity: 0 });

    $(window).on('resize', () => {
        headSplit.revert();
    });

    const opposedLettersTl = gsap.timeline({
        scrollTrigger: {
            trigger: content,
            scrub: true,
            start: 'top 95%',
            end: 'bottom 65%',
        },
    });

    opposedLettersTl.to(linez, {
        x: 0,
        opacity: 1,
        ease: 'linear',
    });
}
