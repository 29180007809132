import gsap from 'gsap';
import { SplitText } from '../vendor/gsap/SplitText';

gsap.registerPlugin(SplitText);

export default function btnAnimate(element) {
    if (!$(element).hasClass('activated')) {
        const btn = element;
        const btnSplit = new SplitText(btn, { type: 'chars, words', charsClass: 'chars', wordsClass: 'words-bro' });
        const btnChars = btnSplit.chars;
        $(element).addClass('activated');
        return btnChars;
    }
    return false;
}

$('.js-btn-split').each((e, index) => {
    btnAnimate(index);
});

$('.link:not(.dropdown-item):not(.footer-link):not(.privacy-policy)').each((e, index) => {
    btnAnimate(index);
});

$('.format-link').each((e, index) => {
    btnAnimate(index);
});
