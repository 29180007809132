// Video overlay embed iframe from button with data-video-url using video url
export default function modalVideoEmbed() {
    const parseVideo = (url) => {
        // Credit to yangshun's YouTube Vimeo URL Parser on github: https://gist.github.com/yangshun/9892961
        let type;

        url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/);

        if (RegExp.$3.indexOf('youtu') > -1) {
            type = 'youtube';
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
            type = 'vimeo';
        }

        return {
            type,
            id: RegExp.$6,
        };
    };

    const modalVidBody = $('.modal .modal-body');

    // Populate video iframe after parsing vimeo or youtube:
    $('.video-overlay-trigger').on('click', (e) => {
        e.preventDefault();
        const hasAutoplay = $(e.currentTarget).attr('data-autoplay-me');
        const url = $(e.currentTarget).attr('data-href');
        const iframe = $('iframe#modal-iframe-embed');
        const videoObj = parseVideo(url);
        let embeddedUrl;

        if (videoObj.type === 'youtube') {
            embeddedUrl = `https://www.youtube.com/embed/${videoObj.id}?rel=0&`;
        } else if (videoObj.type === 'vimeo') {
            embeddedUrl = `https://player.vimeo.com/video/${videoObj.id}?title=0&byline=0&portrait=0&`;
        }

        $(iframe).attr('src', embeddedUrl);

        if (hasAutoplay > 0) {
            $(iframe).attr('src', `${$(iframe).attr('src')}autoplay=1`);
        }

        $(modalVidBody).removeClass('hidden');
    });

    // Stop the video from playing when modal closed
    const modalEls = document.querySelectorAll('.modal');
    modalEls.forEach((element) => {
        element.addEventListener('hidden.bs.modal', () => {
            $('iframe#modal-iframe-embed').attr('src', '');
            $(modalVidBody).addClass('hidden');
        });
    });

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            $('.modal').fadeOut();
        }
    });
}
