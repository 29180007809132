import { gsap, ScrollTrigger, ScrollToPlugin } from 'gsap/all';
import { DrawSVGPlugin } from '../vendor/gsap/DrawSVGPlugin';

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, ScrollToPlugin);

// For long pages like the work pages
if ($('body').hasClass('work') || $('body').hasClass('work-detail') || $('body').hasClass('landing-detail')) {
    // circular progress drawing
    gsap.set('.progress-wrap', { opacity: 0 });
    $('.back-to-top-wrapper').removeClass('d-none');

    gsap.fromTo('.progress-wrap path', {
        drawSVG: 0,
    }, {
        drawSVG: '100%',
        scrollTrigger: {
            start: 0,
            end: 'max',
            scrub: true,

        },
    });

    // show/hide
    gsap.fromTo('.progress-wrap', {
        yPercent: 100,
        autoAlpha: 0,
    }, {
        yPercent: 0,
        autoAlpha: 1,
        scrollTrigger: {
            start: '28%',
            toggleActions: 'play none none reverse',
        },
    });

    // on click, scroll back to top
    document.querySelector('.progress-wrap').addEventListener('click', (e) => {
        gsap.to(window, {
            scrollTo: 0,
            duration: 0.55,
        });
    });
}
