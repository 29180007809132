import $ from 'jquery';
import { gsap, ScrollTrigger } from 'gsap/all';
import LazyLoad from 'vanilla-lazyload';
import itsMobile from './util/is-mobile';
import { ScrollSmoother } from './vendor/gsap/ScrollSmoother';
import smoov from './util/smooth-scroll';
import 'bootstrap/js/dist/modal';
import modalVideoEmbed from './util/video-modal';
import './util/main-menu';
import './util/footer-logo';
import './util/cursor-mouseovers';
import './util/back-to-top';
import './util/filter-tray';
import './home-panels/intro-panel';
import './home-panels/work-panel';
import './home-panels/brand-panel';
import './home-panels/news-panel';
import './home-panels/our-why-panel';
import './home-panels/two-teams-panel';
import './home-panels/contact-panel';
import './text-animations/headlines';
import './text-animations/ctas';
import './text-animations/btn';
import './text-animations/mask-fade';
import './text-animations/paragraphs';
import './text-animations/split-text-animations';
import './sliders/swiper-slider';
import './common-panels/bg-photo-callout';
import * as cookie from './cookie';

import 'htmx.org';

// Send the CSRF Token with AJAX requests
$.ajaxSetup({
    crossDomain: false, // obviates need for sameOrigin test
    beforeSend(xhr, settings) {
        if (!csrfSafeMethod(settings.type)) {
            const csrfToken = cookie.getCookie('csrftoken');
            xhr.setRequestHeader('X-CSRFToken', csrfToken);
        }
    },
});

function scrollToFilterRow() {
    gsap.to(window, {
        duration: 0,
        ease: 'linear',
        scrollTo: '#filter-tray',
    });
}

const searchParams = new URLSearchParams(window.location.search);
const filterBtn = $('.filter-btn-close');

// Scroll to filter tray
if (searchParams.has('filter-close')) {
    setTimeout(() => { scrollToFilterRow(); }, 800);
}

// Scroll to filter tray
if ($(filterBtn).length > 0) {
    setTimeout(() => { scrollToFilterRow(); }, 800);
}

// set up global htmx
window.htmx = require('htmx.org');

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
smoov();

gsap.utils.toArray('.dark-content').forEach((item) => {
    ScrollTrigger.create({
        trigger: item,
        start: 'top',
        end: 'bottom',
        onToggle: () => {
            $('.main-nav').removeClass('over-light');
            $('.main-nav').addClass('over-dark');
        },
    });
});

gsap.utils.toArray('.light-content').forEach((item) => {
    ScrollTrigger.create({
        trigger: item,
        start: 'top',
        end: 'bottom',
        onToggle: () => {
            $('.main-nav').removeClass('over-dark');
            $('.main-nav').addClass('over-light');
        },
    });
});

ScrollTrigger.create({
    trigger: '.masthead',
    start: 'top -=1%',
    end: 'bottom top',
    onEnter: () => {
        $('.main-nav').addClass('over-masthead');
    },
    onEnterBack: () => {
        $('.main-nav').addClass('over-masthead');
    },
    onLeave: () => {
        $('.main-nav').removeClass('over-masthead');
    },
});

modalVideoEmbed();

// Lazyloaders
const pageLazyLoad = new LazyLoad({
    elements_selector: '[loading=lazy]',
    use_native: true, // ← enables hybrid lazy loading
    effect: 'fadeIn',
    threshold: 150,
    skip_invisible: false,
    bind: 'load',
});

pageLazyLoad.update();

if (itsMobile()) {
    $('body').addClass('mobile');
} else {
    $('body').addClass('desktop');
}

// Lazy load sections
gsap.utils.toArray('section:not(.cc-contact-panel-block)').forEach((section) => {
    gsap.set(section, { autoAlpha: 0 });
    ScrollTrigger.create({
        trigger: section,
        start: 'top bottom+=200px',
        end: 'bottom top',
        onEnter: () => {
            gsap.to(section, { duration: 0.9, autoAlpha: 1 });
        },
    });
});

$('.std-img').each((e, i) => {
    const thisImg = i;

    thisImg.addEventListener('load', () => {
        // Refresh scrolltriggers after layout shift
        window.dispatchEvent(new Event('resize'));
    });
});

$('.hover-menu .menu__item').on('mouseover', (e) => {
    $(e.currentTarget).parent().addClass('hover');
});

$('.hover-menu .dropdown-menu').on('mouseover', (e) => {
    $(e.currentTarget).parent().addClass('hover');
});

$('.hover-menu').on('mouseout', (e) => {
    $(e.currentTarget).removeClass('hover');
});
