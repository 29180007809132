import { gsap, ScrollTrigger } from 'gsap/all';
import { SplitText } from '../vendor/gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

let paraLines;

let windowWidth = window.outerWidth;

function paragraphAnimate(element) {
    if (!$(element).hasClass('activated')) {
        const paragraph = element;
        const paraSplit = new SplitText(paragraph, { type: 'lines' });
        paraLines = paraSplit.lines;
    }

    gsap.set(paraLines, {
        opacity: 0,
        y: 15,
    });

    $(element).addClass('activated');

    const tl = gsap.timeline()
        .staggerFromTo(paraLines, 0.35, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, 0.15, '.3');
    return tl;
}

gsap.utils.toArray('.js-split-paragraph').forEach((item) => {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: item,
            scrub: true,
            start: 'top 80%',
            end: 'bottom bottom',
            onEnter: () => {
                paragraphAnimate(item);
            },
        },
    });

    tl.to(item, 1, {
        opacity: 1,
    });
});

// Custom animated paragraph, lines that move up with scroll
function jsAnimScroll() {
    gsap.utils.toArray('.js-animated-scroll-paragraph').forEach(
        (item) => {
            const mySplitText = new SplitText(item, { type: 'lines' });

            $(window).on('resize', () => {
                if (window.outerWidth !== windowWidth) {
                    mySplitText.revert();
                }
                windowWidth = window.outerWidth;
            });

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    scrub: true,
                    start: 'top 90%',
                    end: 'bottom 40%',
                },
            });

            tl.fromTo(mySplitText.lines, {
                opacity: 0,
                duration: 1,
                scale: 0.9,
                y: '105%',
            }, {
                opacity: 1,
                y: '0%',
                stagger: 0.1,
                scale: 1,
            }, 0);
        },
    );
}

// Wait for fonts to load 1st then splittext
window.addEventListener('load', () => {
    jsAnimScroll();
});
