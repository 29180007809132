import { gsap, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

if ($('.contact-us-panel').length > 0) {
    const shape = $('.contact-us-panel .flyout-panel-shape');
    gsap.set(shape, {
        x: -100,
        opacity: 0,
    });
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.contact-us-panel',
            scrub: true,
            start: 'top bottom',
            end: 'bottom bottom',
        },
    });

    tl.to(shape, {
        x: 0,
        opacity: 1,
    });
}
