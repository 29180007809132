import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray('.js-btn-split').forEach((item) => {
    gsap.set(item, {
        scale: 0.7,
    });
    const btnTl = gsap.timeline({
        scrollTrigger: {
            trigger: item,
            scrub: true,
            start: 'top bottom',
            end: 'bottom 90%',
        },
    });

    btnTl.to(item, {
        duration: 1, opacity: 1, scale: 1, ease: 'linear', clearProps: 'all',
    });
});
