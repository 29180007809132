import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

if ($('.brand-philosophy-panel').length > 0) {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.brand-philosophy-panel',
            scrub: true,
            start: 'top bottom',
            end: 'bottom top',
        },
    });

    tl.to('.brand-spinner', { rotateZ: 180, ease: 'linear' });
}
